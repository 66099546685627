<template>
    <div class="costs-page">
        <div class="container-fluid">
            <h1>Koszty</h1>
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'adminCosts',
    data() {
        return {
            isLoading: true,
        }
    },
    computed: {
    },
    methods: {
        
    }
}
</script>
